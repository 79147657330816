const popularProducts = [
    {
        id:1,
        img:"https://d3o2e4jr3mxnm3.cloudfront.net/Mens-Jake-Guitar-Vintage-Crusher-Tee_68382_1_lg.png",
      },
      {
        id:2,
        img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
      },
      {
        id:3,
        img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
      },
      {
        id:4,
        img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
      },
      {
        id:5,
        img:"https://www.pngarts.com/files/3/Women-Jacket-PNG-High-Quality-Image.png",
      },
      {
        id:6,
        img:"https://www.uniqlo.com/jp/ja/contents/feature/masterpiece/common_22ss/img/products/contentsArea_itemimg_16.jpg",
      },
      {
        id:7,
        img:"https://cdn.shopify.com/s/files/1/0010/7538/0279/products/mens-premium-tee-talentless-steel-grey-s-211387.jpg?v=1635423553",
      },
      {
        id:8,
        img:"http://cdn.shopify.com/s/files/1/0783/3649/products/M703YCRO-1_4158c952-36e3-4e4b-b0_grande.jpg?v=1669939882",
      },
      {
        id:9,
        img:"https://i5.walmartimages.com/asr/1d883d1c-d422-46df-8746-da7439c62a09.3d7181ea11e2dc474b10f8d9dbea1584.jpeg",
      },
      {
        id:10,
        img:"https://us.louisvuitton.com/images/is/image/lv/1/PP_VP_L/louis-vuitton-hoodie-ready-to-wear--HIY48WNPH002_PM2_Front%20view.jpg",
      },
];

export default popularProducts;