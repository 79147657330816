import  styled  from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
    flex: 1;
    margin: 3px;
    height: 70vh;
    position: relative;
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    ${mobile({height: "40vh"})}
`;
const Info = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -35px;
`
const Title = styled.h1`
    color: white;
    font-size: 50px;
    margin-bottom: 20px;
`
const Button = styled.button`
    border: none;
    padding: 10px;
    background-color: white;
    color: black;
    cursor: pointer;
    font-weight: 600;

    &: hover {
        background-color: #e9f5f5;
        transform: scale(1.1);
    }
`

const CategoryItem = ({item}) => {
  return (
    <Container>
            <Image src={item.img}/>
            <Info>
                <Title>{item.title}</Title>
                <Button>SHOP NOW</Button>
            </Info>
    </Container>
  )
}

export default CategoryItem