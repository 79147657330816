const categories = [
    {
        id: 1,
        img: "https://i.imgur.com/s7Y6crD.png",
        title: "SHIRTS",
      },
      {
        id: 2,
        img: "https://i.imgur.com/jqdsbzv.jpeg",
        title: "OUTERWEAR",
      },
      {
        id: 3,
        img: "https://i.imgur.com/Zzn3KUF.png",
        title: "HATS",
      },
    ];

export default categories;